<mat-toolbar>
  <span>BCPoprad</span>
</mat-toolbar>

<div class="main">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form>
        <mat-form-field class="example-full-width">
          <mat-label>Meno</mat-label>
          <input #email type="email" matInput placeholder="Ex. pat@example.com" />
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Heslo</mat-label>
          <input #password type="password" matInput />
        </mat-form-field>
      </form>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="login(email.value, password.value)">Login</button>
    </mat-card-actions>
  </mat-card>
</div>
