import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontendCoreModule } from '@bcpoprad/frontend/core';

import * as components from './components';
//import * as services from './services';

import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';

@NgModule({
  imports: [
    CommonModule,
    FrontendCoreModule,
    MatSidenavModule,
    RouterModule.forChild(appRoutes),
  ],
  declarations: [components.LayoutComponent],
  providers: [],
})
export class FrontendLayoutModule {}
