import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from './../../../../../apps/dashboard/src/environments/environment';

@Injectable()
export class AuthService {
	protocol: string = environment.protocol;
	api: string = environment.api;

	constructor(private http: HttpClient) {}

	login(email: string, password: string) {
		const data = { name: email, pass: password };

		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');

		return this.http
			.post(this.protocol + this.api + '/admin/login', data, {
				headers: headers,
			})
			.pipe(
				map((response: any) => {
					if (response) {
						// login successful if there's a jwt token in the response
						const user = response.data;
						if (response.status && user.status) {
							const admin = {
								_id: user._id,
								name: user.name,
								fullName: user.fullName,
								email: user.email,
								token: user.token,
							};
							// store user details and jwt token in local storage to keep user logged in between page refreshes
							localStorage.setItem('currentUser', JSON.stringify(admin));
						}
						return response.data;
					}
				})
			);
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser');
	}
}
