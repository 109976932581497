<mat-toolbar>
	<!-- <button mat-mini-fab aria-label="Toggle menu">
    <mat-icon>menu</mat-icon>
  </button> -->
	<span>BCPoprad</span>
</mat-toolbar>
<mat-drawer-container>
	<mat-drawer mode="side" opened>
		<mat-list>
			<a [routerLink]="'devices'" mat-list-item>Zariadenia</a>
			<mat-divider></mat-divider>
			<a [routerLink]="'modules'" mat-list-item>Moduly</a>
			<mat-divider></mat-divider>
			<a [routerLink]="'outputs'" mat-list-item>Výstupy</a>
			<mat-divider></mat-divider>
			<a [routerLink]="'users'" mat-list-item>Užívatelia</a>
			<mat-divider></mat-divider>
			<a href="#" mat-list-item (click)="logout()">Odhlásiť</a>
		</mat-list>
	</mat-drawer>
	<mat-drawer-content>
		<router-outlet></router-outlet>
	</mat-drawer-content>
</mat-drawer-container>
