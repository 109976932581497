import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree | boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser === null || currentUser === undefined) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    return true;

    // return this._userService.verify(path).pipe(
    //   map(
    //     (data) => {
    //       if (data !== null) {
    //         if (data.status === 'unauthorized') {
    //           this._router.navigate(['/error/401']);
    //           return false;
    //         }
    //         return true;
    //       }
    //       // error when verify so redirect to login page with the return url
    //       this._router.navigate(['/login'], {
    //         queryParams: { returnUrl: state.url },
    //       });
    //       return false;
    //     },
    //     (error) => {
    //       // error when verify so redirect to login page with the return url
    //       this._router.navigate(['/login'], {
    //         queryParams: { returnUrl: state.url },
    //       });
    //       return false;
    //     }
    //   )
    // );
  }
}
