import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontendCoreModule } from '@bcpoprad/frontend/core';

import * as components from './components';
import * as services from './services';

@NgModule({
  imports: [CommonModule, FrontendCoreModule],
  declarations: [components.LoginComponent],
  providers: [services.AuthService],
})
export class FrontendAuthModule {}
