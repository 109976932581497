import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from './../../../../../apps/dashboard/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BCHttpService {
  constructor(private http: HttpClient) {}

  get(url: string) {
    return this.http.get(this.getUrl(url), { headers: this.jwt() });
  }

  post(url: string, body: any) {
    return this.http.post(this.getUrl(url), body, { headers: this.jwt() });
  }

  delete(url: string) {
    return this.http.delete(this.getUrl(url), { headers: this.jwt() });
  }

  private getUrl(url: string) {
    return environment.protocol + environment.api + url;
  }

  private jwt() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      return new HttpHeaders().append(
        'Authorization',
        'Bearer ' + currentUser.token
      );
    }
    return new HttpHeaders();
  }
}
