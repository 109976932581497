import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FrontendAuthModule } from '@bcpoprad/frontend/auth';
import { FrontendLayoutModule } from '@bcpoprad/frontend/layout';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppGuard } from './app.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    HttpClientModule,
    FrontendAuthModule,
    FrontendLayoutModule,
    BrowserAnimationsModule,
  ],
  providers: [AppGuard],

  bootstrap: [AppComponent],
})
export class AppModule {}
