import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import {
	MatSnackBarModule,
	MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';

import * as services from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		//Material
		MatToolbarModule,
		MatCardModule,
		MatButtonModule,
		MatGridListModule,
		MatInputModule,
		MatDividerModule,
		MatIconModule,
		MatListModule,
		MatTableModule,
		MatDialogModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		MatSelectModule,
	],
	exports: [
		RouterModule,
		MatToolbarModule,
		FormsModule,
		ReactiveFormsModule,
		MatCardModule,
		MatButtonModule,
		MatGridListModule,
		MatInputModule,
		MatDividerModule,
		MatIconModule,
		MatListModule,
		MatTableModule,
		MatDialogModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		MatSelectModule,
	],
	//declarations: [services.BCHttpService],
	providers: [
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
	],
})
export class FrontendCoreModule {}
