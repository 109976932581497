import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from './../../../../../apps/dashboard/src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private router: Router) {}

	public getCurrentUser() {
		return JSON.parse(localStorage.getItem('currentUser'));
	}

	public isLoggedIn(): boolean {
		return this.getCurrentUser() ? true : false;
	}

	public logout() {
		localStorage.setItem('currentUser', null);
		this.router.navigate(['login']);
	}

	private jwt() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser && currentUser.token) {
			return new HttpHeaders().append(
				'Authorization',
				'Bearer ' + currentUser.token
			);
		}
		return new HttpHeaders();
	}
}
