import { Component } from '@angular/core';
import { AuthService } from 'packages/frontend/core/src/lib/services/auth.service';

@Component({
	selector: 'bc-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
	constructor(private authService: AuthService) {}

	logout() {
		this.authService.logout();
	}
}
