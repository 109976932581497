import { Route, Routes } from '@angular/router';
import { LoginComponent } from '@bcpoprad/frontend/auth';
import { LayoutComponent } from '@bcpoprad/frontend/layout';
import { AppComponent } from './app.component';
import { AppGuard } from './app.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: LayoutComponent, canActivate: [AppGuard] },
    ],
  },
];
