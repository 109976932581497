// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let production = false;
let protocol = 'http://';
let api = 'localhost:8081/api';

production = false;
protocol = 'https://';
api = 'ebuilding.kuckovsky.com/api';

export const environment = {
	production: production,
	protocol: protocol,
	api: api
};
