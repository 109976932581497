import { Route, Routes } from '@angular/router';
import { LayoutComponent } from './components';
import { authGuard } from '@bcpoprad/frontend/core';

export const appRoutes: Route[] = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [authGuard],
		children: [
			{
				path: '',
				loadChildren: () =>
					import('@bcpoprad/frontend/control').then(
						(m) => m.FrontendControlModule
					),
			},
		],
	},
];
