import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'bc-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	constructor(
		private authService: AuthService,
		private router: Router,
		private _snackBar: MatSnackBar
	) {}

	login(username: string, password: string) {
		this.authService.login(username, password).subscribe((resp) => {
			if (resp?.status) {
				this.router.navigate(['/']);
			} else {
				this._snackBar.open('Nesprávne prihlasovacie údaje');
			}
		});
	}
}
